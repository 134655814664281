<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Editar Alunos</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items" />
    </div>
    <br />
    <ConfirmarEnvioDeDados
      @prevPage="prevPage"
      @enviarDados="enviarDados"
      :dadosPessoa="dadosPessoa"
    />
    <pm-Toast />
  </section>
</template>

<script>
import Step2 from "@/components/steps2.vue";
import ConfirmarEnvioDeDados from "@/components/alunos/ConfirmarEnvioDeDados.vue";

import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";
import { Aluno } from "@/class/alunos";

export default {
  props: {
    basic: {},
    pg: {},
  },
  components: {
    Step2,
    ConfirmarEnvioDeDados,
  },
  name: "confirmarEnvio",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Editar Aluno" }],
      dadosPessoa: [],
      items: [
        {
          number: "1",
          label: "Informações básicas",
          to: "aluno-basic-info-cadastrar",
          active: 0,
        },
        {
          number: "2",
          label: "Conectividade e acesso ao sistema",
          to: "2",
          active: 0,
        },
        {
          number: "3",
          label: "Endereço e Transporte",
          to: "3",
          active: 0,
        },
        {
          number: "4",
          label: "Confirmar",
          to: "4",
          active: 1,
        },
      ],
    };
  },
  methods: {
    prevPage(info) {
      this.$router.push({
        name: "endereco-contato-editar",
        params: { basic: JSON.stringify(info), pg: this.pg },
      });
    },
    async enviarDados(info) {
      const telefonesDaPessoa = [];
      for (const el of info.info.telefone) {
        telefonesDaPessoa.push({
          nome: el.nome,
          identificador: el.identificador,
        });
      }

      let qualtransporte = "";
      if (info.info.utiliza_transporte == 1) {
        qualtransporte = info.info.qual_transporte;
      }

      // // let qualtransporte = "";
      // if (info.info.utiliza_transporte == 1) {
      //   qualtransporte = info.info.qual_transporte;
      // }

      const aluno = {
        id: info.info.id,
        login_id: info.info.login_id,
        nome: info.info.nome,
        sobrenome: info.info.sobrenome,
        nome_mae: info.info.nomeMae,
        nome_pai: info.info.nomePai,
        certidao_nascimento: info.info.certidaoNascimento,
        certidao_casamento: info.info.certidaoCasamento,
        email: info.info.email == "" ? info.info.emailDele : info.info.email,
        rg: info.info.rg,
        cpf: info.info.cpf,
        nascimento: info.info.dataNascimento,
        sexo: info.info.sexo,
        deficiencia: info.info.possuiDeficiencia,
        qual_deficiencia: info.info.deficiencia,
        observacao: info.info.observacao,
        logradouro: info.info.logradouro,
        numero: info.info.numero,
        complemento: info.info.complemento,
        bairro: info.info.bairro,
        cidade: info.info.cidade,
        estado: info.info.estado,
        cep: info.info.cep,
        rg_data_missao: info.info.rgDataEmissao,
        sexo: info.info.sexo,
        nee: info.info.nee,
        mediacao_escolar: info.info.mediacao_escolar,
        password: info.info.senha,
        user_id: store_token_info.usuario_logado,
        telefone: telefonesDaPessoa,
        alergico: info.info.alergico,
        tipo_sanguineo: info.info.tipo_sanguineo,
        tipo_alergia: info.info.tipo_alergia,
        nacionalidade: info.info.nacionalidade,
        naturalidade: info.info.naturalidade,
        cor_raca: info.info.cor,
        utiliza_transporte: info.info.utiliza_transporte,
        religiao: info.info.religiao,
        freq_aula_religioso: info.info.aulas_religiao_frequentara,
        autorizar_uso_imagem: info.info.usoDaImage,
        rg_orgao_emissor: info.info.orgao_emissor,
        livro: info.info.livro_rg,
        rg_uf: info.info.uf_rg,
        folha: info.info.folha_rg,
        termo: info.info.termo_rg,
        nis: info.info.nis,
        sus: info.info.sus,
        dispositivo_com_acesso_internet: info.info.conectividade,
        tipos_dipositivos: info.info.tipos_dispotivos,
        acesso_celular: [],
        acesso_computador: [],
        acesso_tablet: [],
        acesso_outros: [],
        transporte: parseInt(info.info.utiliza_transporte),
        qual_transporte: qualtransporte,
        nome_responsavel: info.info.responsavel,
        estadocivil: info.info.estadocivil,
      };

      //verificar dispositivos
      for (const el of aluno.tipos_dipositivos) {
        if (el === "CELULAR") {aluno.acesso_celular = 1;}
        if (el === "COMPUTADOR") {aluno.acesso_computador = 1;}
        if (el === "TABLET") {aluno.acesso_tablet = 1;}
        if (el === "OUTROS") {aluno.acesso_outros = 1;}
      }
      try {
        const data = await Aluno.alterar(aluno);

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          color: "success",
          fullWidth: false,
        });
        this.$router.push({ name: "alunosAll", params: { pg: this.pg } });
      } catch (e) {}
    },
  },
  beforeMount() {
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
    }
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
